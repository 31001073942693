<template>
    <div>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <CircularProgress v-if="loader"/>
        <v-card color="#373535" elevation="0">
            <v-row no-gutters>
                <v-col class="mt-7 pl-7" cols="9">
                    <div class="mt-2">
                    <span class="pageTitle">Payment</span>
                    </div>
                </v-col>
                <v-col class="mt-8 pr-4 text-right" cols="3">
                    <v-btn @click="toListPayment()" fab dark class="" color="#434242" elevation="0" small>
                    <v-icon size="15" color="#ffffff" dark>$xMarkIcon</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-list color="#373535" class="text-white" v-if="orderItemStatus">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="customerName text-white">{{orderItem.customerName}}</v-list-item-title>
                        <span><v-chip small :color="(orderItem.orderPaymentDetails.status=='paid')?'#4B9C6D':'#d53d3d'" class="my-1 text-white text-capitalize">{{orderItem.orderPaymentDetails.status.replace('_',' ')}}</v-chip></span>
                        <span><v-chip small color="#4B9C6D" class="my-2 text-capitalize text-white">{{orderItem.orderStatus.replace('_',' ')}}</v-chip></span>
                    </v-list-item-content>

                    <v-list-item-content class="text-right">
                        <span class="houseNo text-white">{{orderItem.customerHouseNo}}</span>
                        <span class="houseText text-white">{{orderItem.customerCommunityName}}</span>
                        <span class="mt-4"></span>
                    </v-list-item-content>

                </v-list-item>
                <v-divider class="ml-5 mr-5 dividerLine"></v-divider>
                <div class="ml-5 mt-3 mb-3 mr-5">
                    <v-expansion-panels accordion dark class="mb-3">
                        <v-expansion-panel >
                            <v-expansion-panel-header color="#373535" dark  >Order No. #{{orderItem.orderSeriesNo}}</v-expansion-panel-header>
                            <v-expansion-panel-content color="#373535" dark>
                                <div class="row">
                                    <div class="col-6"><span class="listTitle">Order Source</span>  <br><span class="capitaliseText">{{orderItem.orderSource}}</span>   </div>
                                    <div class="col-6">
                                      <span class="listTitle">Order Date</span>  <br><span class="listContent">{{moment(orderItem.orderDate).format("MMMM Do YYYY, h:mm a")}}</span>
                                    </div>
                                </div>
                                <v-simple-table light class="my-2">
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th class="text-left">
                                          Product
                                        </th>
                                        <th class="text-left">
                                          Quantity
                                        </th>
                                        <th class="text-left">
                                          Items
                                        </th>
                                        <th class="text-left">
                                          Price
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="item in orderItem.cartList" :key="item._id">
                                        <td>{{ item.productId.productName }} / {{ item.productId.productDescription }}</td>
                                        <td>{{ item.productBaseQuantity }}</td>
                                        <td>{{ item.orderQuantity }}</td>
                                        <td>{{ item.productTotalBill }}</td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <div class="row">
                        <div class=" col-6 pb-0">
                            <span class="listTitle">Bill Amount</span>
                        </div>
                        <div class=" col-6 text-right pb-0">
                            <span class="listContent">{{orderItem.orderBillAmount}}</span>
                        </div>
                        <div class=" col-6 pb-0">
                            <span class="listTitle">Refund</span>
                        </div>
                        <div class=" col-6 text-right pb-0">
                            <span class="listContent">{{refund}}</span>
                        </div>
                    </div>
                    <div v-if="orderItem.orderPaymentDetails.refunds.length>0">
                        <div class="row mt-0" :key="index" v-for="(refundItem,index) in orderItem.orderPaymentDetails.refunds" >
                            <div class=" col-9 pb-0 ">
                                <span class="listTitle">{{refundItem.reason}} ({{refundItem.status.toUpperCase()}})</span>
                            </div>
                            <div class=" col-3 pb-0 text-right">
                                <span class="listTitle">{{refundItem.amount}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" col-12 mt-3"><hr /></div>
                        <div class=" col-6 pb-0 pt-0">
                            <span class="listTitle">Net Amount</span>
                        </div>
                        <div class=" col-6 text-right pb-0 pt-0">
                            <span class="listContent">{{netAmt}}</span>
                        </div>

                    </div>
                    <div class="row" v-if="orderItem.orderPaymentDetails.status=='not_paid' && orderItem.orderPaymentDetails.paymentLink" >
                        <div class="col-4 mt-3">
                            <span class="listTitle">Payment Link</span>
                        </div>
                        <div class="col-8 mt-3 text-right">
                            <span> {{orderItem.orderPaymentDetails.paymentLink}} </span>
                            <!-- <v-btn @click="toCopyPaymentURL(orderItem.orderPaymentDetails.paymentLink)" fab class="bg-white" color="#434242" elevation="0" small>
                                <v-icon size="15" color="#ffffff" >$copy</v-icon>
                            </v-btn> -->
                        </div>
                      </div>
                      <div class="row" v-if="orderItem.orderPaymentDetails.status=='not_paid'">
                        <div class="col-9">
                            <span class="listTitle">Collected Amount Offline</span>
                        </div>
                        <div class="col-3 text-right">
                            <v-switch
                            v-model="offlineAmt"
                            label=""
                            class="ma-0"
                            color="success"
                            value="success"
                            hide-details
                            @change="offlinePayment()"
                            ></v-switch>
                        </div>
                    </div>
                    <div class="row" v-if="orderItem.orderPaymentDetails.status=='paid'" >
                        <div class="col-12 mt-3 text-center">
                            <span class="listTitle text-capitalize">Received By {{orderItem.orderPaymentDetails.channel}}</span>
                        </div>
                    </div>
                    <div class="text-center my-5" v-if="btnRefund && orderItem.orderStatus !='cancelled' ">
                        <v-btn @click="refundDialogOpen()" fab color="#434242" class="w-100 text-white" elevation="0">
                            RAISE REFUND
                        </v-btn>
                    </div>
                </div>
                <v-divider class="ml-5 mr-5 dividerLine"></v-divider>
            </v-list>
            <v-snackbar :timeout="5000" :value="true" v-if="responseMessage !=''"  absolute bottom color="#62e14b" text>
            {{responseMessage}}
            </v-snackbar>
        </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogRefund"
      width="500"
      fab dark
    >
      <v-card>
        <v-card-title
          class="headline "
          primary-title
          fab dark
        >
          Raise Refund
        </v-card-title>

        <v-card-text>
            <v-form ref="form">
                <v-text-field
                label="Amount"
                :rules="rulesAmt"
                hide-details="auto"
                type="number"
                class="mb-2"
                v-model="dialogRefundAmt"
                ></v-text-field>

                <v-textarea
                    name="input-7-1"
                    filled
                    label="Reason"
                    auto-grow
                    :rules="rulesAmt"
                    v-model="dialogRefundReason"
                ></v-textarea>
            </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#ff9800"
            @click="createRaiseRefund()"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogConfirm"
      persistent
      max-width="290"
      fab dark
    >
      <v-card>
        <v-card-title class="text-h5">
          Are you sure?
        </v-card-title>
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialogConfirmDisagree()"
          >
            Disagree
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="dialogConfirmAgree()"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
</template>
<script>
import CircularProgress from '../loaders/CircularProgress.vue';
import orderServices from '@/services/orderServices';
import router from '@/router';
import moment from 'moment';

  export default {
    name: 'EditPayment',
    components: {
        CircularProgress
    },
    data () {
      return {
        loader: false,
        dialog: true,
        dialogRefund: false,
        btnRefund: false,
        dialogConfirm: false,
        dialogRefundAmt: '',
        dialogRefundReason: '',
        responseMessage: "",
        refund:0,
        offlineAmt:false,
        netAmt:0,
        moment: moment,
        orderItem:{
            orderId: '',
            orderType: '',
            orderSeriesNo: '',
            orderSource:'',
            orderNote: '',
            orderDate: '',
            customerName: '',
            customerId: '',
            customerHouseNo: '',
            cartItemsCount: '',
            cartItemsList: '',
            orderPayment: '',
            orderBillAmount: '',
            orderStatus: ''
        },
        orderItemStatus:false,
        rulesAmt: [
            value => !!value || 'Required'
        ],
      }
    },
    methods: {
      toListPayment () {
        this.dialog = false;
        if( typeof this.$route.query.page !== 'undefined' ){
            router.push('/basket');
        }
        else{
            router.push('/paymentList');
        }
      },
      toCopyPaymentURL( copyText ){
          navigator.clipboard.writeText(copyText);
      },
      refundDialogOpen(){
          this.dialogRefund = true;
      },
      refundDialogClose(){
        this.dialogRefund = false;
      },
      offlinePayment(){
          this.dialogConfirm = true;
      },
      dialogConfirmDisagree(){
          this.dialogConfirm = false;
          this.offlineAmt = false;
      },
      async dialogConfirmAgree(){
            this.loader = true;
            this.dialogConfirm = false;
            const item = {
                'orderId':this.orderItem.orderId,
                'channel': 'partner'
            };
            try {
                await orderServices.updatePaymentStatus(item).then(result => {
                    if (result.data.order) {
                        this.orderItem = result.data.order;
                        this.responseMessage = result.data.message;
                        this.setRefundAndNetAmt();
                        this.loader = false;
                    } else {
                        console.log(result);
                        this.loader = false;
                    }
                })
            } catch (err) {
                console.log(err)
            }
      },
      async createRaiseRefund(){
          if (this.$refs.form.validate()) {
                this.loader = true;
                const item = {
                    'orderId':this.orderItem.orderId,
                    'amount':this.dialogRefundAmt,
                    'reason':this.dialogRefundReason
                };
                try {
                    await orderServices.raiseRefund(item).then(result => {
                        if (result.data.order) {
                            this.orderItem = result.data.order;
                            this.responseMessage = result.data.message;
                            this.dialogRefund = false;
                            this.dialogRefundAmt = '';
                            this.dialogRefundReason = '';
                            this.setRefundAndNetAmt();
                            this.loader = false;
                        } else {
                            this.loader = false;
                        }
                    })
                } catch (err) {
                    console.log(err)
                }
          }
      },
        setRefundAndNetAmt(){
            this.refund = 0;
            if( this.orderItem.orderPaymentDetails.refunds.length>0 ){
                var tRefund = 0;
                var pRefund = 0;
                this.orderItem.orderPaymentDetails.refunds.map(( item )=>{
                    if( item.status!=="raised" ){
                        pRefund += item.amount;
                    }
                    tRefund += item.amount;
                })
                this.refund = tRefund;
                if( typeof this.orderItem.orderPaymentDetails.amount !== 'undefined' ){
                    this.netAmt = this.orderItem.orderPaymentDetails.amount;
                }
                else{
                    this.netAmt = this.orderItem.orderBillAmount - pRefund;
                }
            }
            else{
                this.netAmt = this.orderItem.orderBillAmount - this.refund;
            }
        }
    },
    async mounted () {

      const getAllActiveOrders = (await orderServices.getCustomerOrder(this.$route.params.id)).data;

      if( getAllActiveOrders.customerOrderDetails.length>0 ){
            this.orderItem = getAllActiveOrders.customerOrderDetails[0];
            const date1 = new Date();
            const date2 = new Date(this.orderItem.orderDate);
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if( diffDays <= 3 ){
                this.btnRefund = true;
            }
            this.setRefundAndNetAmt();
            this.orderItemStatus = true;
      }
      else{
          this.orderItemStatus = false;
      }
    }
  }
</script>
<style scoped>
.pageTitle {
font-family: Lato;
font-style: normal;
font-weight: 900;
font-size: 18px;
color: #FFFFFF;
}
.saveCustomer {
text-transform: unset !important;
background: #4B9C6D;
font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 29px;
color: #FFFFFF;
opacity: 0.8;
}
.text-white {
    color: #fff !important;
}
.dividerLine{
    border-color: rgba(255, 255, 255, 0.5) !important;
}
.bg-white {
    background: #ff9800 !important;
}
button.w-100 {
    width: 100% !important;
    border-radius: 25px !important;
    font-weight: bold;
    background: #ff9800 !important;
}
.text-capitalize {
    text-transform: capitalize !important;
}
</style>
